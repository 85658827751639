"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  AuthContext: () => AuthContext,
  AuthProvider: () => AuthProvider,
  configureAuth: () => configureAuth,
  currentSession: () => currentSession,
  useActivityDetection: () => useActivityDetection,
  useAuth: () => useAuth,
  useBackgroundColor: () => useBackgroundColor,
  useGraphQLClient: () => useGraphQLClient,
  useInput: () => useInput
});
module.exports = __toCommonJS(src_exports);

// src/auth/index.tsx
var import_react = __toESM(require("react"));
var import_aws_amplify = require("aws-amplify");
var import_auth = require("aws-amplify/auth");
var import_utils = require("aws-amplify/utils");

// src/utils.ts
var import_schema = require("@drumbeat/schema");
var generatePassword = (length) => {
  const specialCharacters = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
  const numbers = "0123456789";
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const allCharacters = specialCharacters + numbers + letters;
  let password = "";
  for (let i3 = 0; i3 < length; i3++) {
    const character = Math.floor(Math.random() * allCharacters.length);
    password += allCharacters.substring(character, character + 1);
  }
  const specialChar1 = Math.floor(Math.random() * specialCharacters.length);
  const specialChar2 = Math.floor(Math.random() * specialCharacters.length);
  const number1 = Math.floor(Math.random() * numbers.length);
  const number2 = Math.floor(Math.random() * numbers.length);
  password += specialCharacters.substring(specialChar1, specialChar1 + 1) + specialCharacters.substring(specialChar2, specialChar2 + 1) + numbers.substring(number1, number1 + 1) + numbers.substring(number2, number2 + 1);
  return password;
};
var USER_TYPE_COGNITO_CLIENT_ID_MAP = {
  [import_schema.UserType.Therapist]: process.env.NEXT_PUBLIC_THERAPIST_USER_POOL_WEB_CLIENT_ID,
  [import_schema.UserType.Admin]: process.env.NEXT_PUBLIC_ADMIN_USER_POOL_WEB_CLIENT_ID,
  [import_schema.UserType.Client]: process.env.NEXT_PUBLIC_CLIENT_USER_POOL_WEB_CLIENT_ID
};
var USER_TYPES = Object.keys(USER_TYPE_COGNITO_CLIENT_ID_MAP);

// src/auth/index.tsx
var currentAmplifyAuthConfig = null;
var configureAuth = (config) => {
  if (currentAmplifyAuthConfig)
    return;
  const authConfig = {
    Cognito: {
      userPoolId: config.userPoolId,
      userPoolClientId: config.userPoolWebClientId,
      loginWith: {
        email: true,
        oauth: {
          domain: config.cognitoDomain,
          scopes: ["openid"],
          redirectSignIn: [config.redirectSignIn],
          redirectSignOut: [config.redirectSignOut],
          responseType: "code",
          // or 'token', note that REFRESH token will only be generated when the responseType is code
          providers: ["Google"]
        }
      }
    }
  };
  const amplifyConfig = {
    Auth: { ...authConfig }
  };
  import_aws_amplify.Amplify.configure(amplifyConfig);
  currentAmplifyAuthConfig = amplifyConfig;
};
var AuthContext = import_react.default.createContext({
  onSignedOutFunc: () => Promise.resolve()
});
var AuthProvider = ({ children, config }) => {
  configureAuth({ ...config });
  const { onSignedOutFunc } = config;
  import_react.default.useEffect(() => {
    const unsubscribe = import_utils.Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          break;
        case "signedOut":
          break;
        case "tokenRefresh":
          break;
        case "tokenRefresh_failure":
          break;
        case "signInWithRedirect":
          break;
        case "signInWithRedirect_failure":
          break;
        case "customOAuthState":
          break;
      }
    });
    return unsubscribe;
  }, [config]);
  return /* @__PURE__ */ import_react.default.createElement(AuthContext.Provider, { value: { onSignedOutFunc } }, children);
};
async function currentSession(forceRefresh = false) {
  try {
    const { accessToken, idToken } = (await (0, import_auth.fetchAuthSession)({ forceRefresh })).tokens ?? {};
    return { accessToken, idToken };
  } catch (err) {
    console.log(err);
    throw err;
  }
}
var useAuth = () => {
  const [userObj, setUserObj] = import_react.default.useState({
    user: null,
    isLoading: true
  });
  const [accessToken, setAccessToken] = (0, import_react.useState)();
  const accessTokenString = accessToken?.toString();
  const [isLoading, setIsLoading] = (0, import_react.useState)(false);
  const [customState, setCustomState] = import_react.default.useState(
    void 0
  );
  const updateUser = async (user) => {
    setUserObj({ user, isLoading: false });
  };
  const signIn = import_react.default.useCallback(
    async ({ email, password, clientMetadata }) => {
      const signInResponse = await (0, import_auth.signIn)({
        username: email,
        options: { authFlowType: "CUSTOM_WITHOUT_SRP", clientMetadata }
      });
      if (signInResponse.isSignedIn) {
        const user = await (0, import_auth.getCurrentUser)();
        await updateUser(user);
        return user;
      }
      if (!signInResponse.nextStep) {
        throw new Error("Unable to sign in");
      }
    },
    [setUserObj]
  );
  const signOut = import_react.default.useCallback(async () => {
    await (0, import_auth.signOut)({ global: true });
    await updateUser(null);
  }, [setUserObj]);
  const signInWithGoogle = import_react.default.useCallback(async () => {
    await (0, import_auth.signOut)({ global: true });
    await (0, import_auth.signInWithRedirect)({
      provider: "Google"
    });
  }, []);
  const signUpWithEmail = import_react.default.useCallback(
    async (email) => {
      const cognitoUser = await (0, import_auth.signUp)({
        username: email,
        password: generatePassword(10),
        options: {
          userAttributes: {
            email
          }
        }
      });
      return cognitoUser;
    },
    [setUserObj]
  );
  const checkIfRefreshTokenIsValid = import_react.default.useCallback(async () => {
    try {
      const tokens = await currentSession();
      if (!tokens?.accessToken) {
        return false;
      }
      setAccessToken(tokens.accessToken);
      return true;
    } catch (error2) {
      setAccessToken(void 0);
      console.warn(error2);
      return false;
    }
  }, []);
  const refreshAccessToken = import_react.default.useCallback(async () => {
    try {
      const user = await (0, import_auth.getCurrentUser)();
      await updateUser(user);
    } catch (e3) {
      updateUser(null);
      console.error("Unable to refresh Token", e3);
    }
  }, []);
  const signInWithEmail = import_react.default.useCallback(async (email) => {
    await (0, import_auth.signOut)();
    await signIn({ email });
    await checkIfRefreshTokenIsValid();
  }, []);
  const authenticate = import_react.default.useCallback(
    async (magicLink, email) => {
      await (0, import_auth.signOut)();
      const fixedMagicLink = magicLink ? magicLink : null;
      const fixedEmail = email ? email.replaceAll(" ", "+") : null;
      if (fixedEmail && fixedMagicLink) {
        const signInResponse = await (0, import_auth.signIn)({
          username: fixedEmail,
          options: { authFlowType: "CUSTOM_WITHOUT_SRP" }
        });
        if (signInResponse.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
          const confirmSignInResponse = await (0, import_auth.confirmSignIn)({
            challengeResponse: fixedMagicLink
          });
          if (confirmSignInResponse.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
            return "INVALID_INPUT_DATA";
          }
          if (confirmSignInResponse.isSignedIn) {
            const authSession = await (0, import_auth.fetchAuthSession)();
            setAccessToken(authSession?.tokens?.accessToken);
            return "SIGNED_IN";
          }
          return "FAILED_CUSTOM_SIGN_IN";
        } else if (signInResponse.nextStep.signInStep === "DONE") {
          return "SIGNED_IN";
        }
      }
      return "INVALID_INPUT_DATA";
    },
    []
  );
  const refreshNewTokens = async () => {
    try {
      const authSession = await (0, import_auth.fetchAuthSession)({ forceRefresh: true });
      if (!authSession?.tokens?.accessToken) {
        return false;
      }
      setAccessToken(authSession.tokens.accessToken);
      return true;
    } catch (error2) {
      console.error("Unable to refresh Token", error2);
      return false;
    }
  };
  const value2 = (0, import_react.useMemo)(
    () => ({
      user: userObj.user,
      accessToken,
      isLoading,
      signInWithGoogle,
      signInWithEmail,
      signUpWithEmail,
      signOut,
      refreshAccessToken,
      checkIfRefreshTokenIsValid,
      authenticate,
      fetchAuthSession: import_auth.fetchAuthSession,
      refreshNewTokens
    }),
    [userObj, accessTokenString]
  );
  return value2;
};

// src/useBackgroundColor.tsx
var import_react2 = require("react");
var useBackgroundColor = (color) => {
  (0, import_react2.useEffect)(() => {
    const handleRouteChange = () => {
      document.documentElement.style.backgroundColor = color;
      document.body.style.backgroundColor = color;
    };
    handleRouteChange();
    return () => {
      document.documentElement.style.backgroundColor = "";
      document.body.style.backgroundColor = "";
    };
  }, [color]);
};

// src/graphqlClient.ts
var import_react3 = require("react");

// ../../node_modules/@urql/core/node_modules/@0no-co/graphql.web/dist/graphql.web.mjs
var e = {
  NAME: "Name",
  DOCUMENT: "Document",
  OPERATION_DEFINITION: "OperationDefinition",
  VARIABLE_DEFINITION: "VariableDefinition",
  SELECTION_SET: "SelectionSet",
  FIELD: "Field",
  ARGUMENT: "Argument",
  FRAGMENT_SPREAD: "FragmentSpread",
  INLINE_FRAGMENT: "InlineFragment",
  FRAGMENT_DEFINITION: "FragmentDefinition",
  VARIABLE: "Variable",
  INT: "IntValue",
  FLOAT: "FloatValue",
  STRING: "StringValue",
  BOOLEAN: "BooleanValue",
  NULL: "NullValue",
  ENUM: "EnumValue",
  LIST: "ListValue",
  OBJECT: "ObjectValue",
  OBJECT_FIELD: "ObjectField",
  DIRECTIVE: "Directive",
  NAMED_TYPE: "NamedType",
  LIST_TYPE: "ListType",
  NON_NULL_TYPE: "NonNullType"
};
var GraphQLError = class extends Error {
  constructor(e3, r2, i3, n2, a2, t2, l3) {
    super(e3);
    this.name = "GraphQLError";
    this.message = e3;
    if (a2) {
      this.path = a2;
    }
    if (r2) {
      this.nodes = Array.isArray(r2) ? r2 : [r2];
    }
    if (i3) {
      this.source = i3;
    }
    if (n2) {
      this.positions = n2;
    }
    if (t2) {
      this.originalError = t2;
    }
    var o2 = l3;
    if (!o2 && t2) {
      var u3 = t2.extensions;
      if (u3 && "object" == typeof u3) {
        o2 = u3;
      }
    }
    this.extensions = o2 || {};
  }
  toJSON() {
    return {
      ...this,
      message: this.message
    };
  }
  toString() {
    return this.message;
  }
  get [Symbol.toStringTag]() {
    return "GraphQLError";
  }
};
var i;
var n;
function error(e3) {
  return new GraphQLError(`Syntax Error: Unexpected token at ${n} in ${e3}`);
}
function advance(e3) {
  e3.lastIndex = n;
  if (e3.test(i)) {
    return i.slice(n, n = e3.lastIndex);
  }
}
var a = / +(?=[^\s])/y;
function blockString(e3) {
  var r2 = e3.split("\n");
  var i3 = "";
  var n2 = 0;
  var t2 = 0;
  var l3 = r2.length - 1;
  for (var o2 = 0; o2 < r2.length; o2++) {
    a.lastIndex = 0;
    if (a.test(r2[o2])) {
      if (o2 && (!n2 || a.lastIndex < n2)) {
        n2 = a.lastIndex;
      }
      t2 = t2 || o2;
      l3 = o2;
    }
  }
  for (var u3 = t2; u3 <= l3; u3++) {
    if (u3 !== t2) {
      i3 += "\n";
    }
    i3 += r2[u3].slice(n2).replace(/\\"""/g, '"""');
  }
  return i3;
}
function ignored() {
  for (var e3 = 0 | i.charCodeAt(n++); 9 === e3 || 10 === e3 || 13 === e3 || 32 === e3 || 35 === e3 || 44 === e3 || 65279 === e3; e3 = 0 | i.charCodeAt(n++)) {
    if (35 === e3) {
      while (10 !== (e3 = i.charCodeAt(n++)) && 13 !== e3) {
      }
    }
  }
  n--;
}
var t = /[_A-Za-z]\w*/y;
var l = new RegExp("(?:(null|true|false)|\\$(" + t.source + ')|(-?\\d+)((?:\\.\\d+)?[eE][+-]?\\d+|\\.\\d+)?|("""(?:"""|(?:[\\s\\S]*?[^\\\\])"""))|("(?:"|[^\\r\\n]*?[^\\\\]"))|(' + t.source + "))", "y");
var o = function(e3) {
  e3[e3.Const = 1] = "Const";
  e3[e3.Var = 2] = "Var";
  e3[e3.Int = 3] = "Int";
  e3[e3.Float = 4] = "Float";
  e3[e3.BlockString = 5] = "BlockString";
  e3[e3.String = 6] = "String";
  e3[e3.Enum = 7] = "Enum";
  return e3;
}(o || {});
var u = /\\/g;
function value(e3) {
  var r2;
  var a2;
  l.lastIndex = n;
  if (91 === i.charCodeAt(n)) {
    n++;
    ignored();
    var d3 = [];
    while (93 !== i.charCodeAt(n)) {
      d3.push(value(e3));
    }
    n++;
    ignored();
    return {
      kind: "ListValue",
      values: d3
    };
  } else if (123 === i.charCodeAt(n)) {
    n++;
    ignored();
    var v3 = [];
    while (125 !== i.charCodeAt(n)) {
      if (null == (r2 = advance(t))) {
        throw error("ObjectField");
      }
      ignored();
      if (58 !== i.charCodeAt(n++)) {
        throw error("ObjectField");
      }
      ignored();
      v3.push({
        kind: "ObjectField",
        name: {
          kind: "Name",
          value: r2
        },
        value: value(e3)
      });
    }
    n++;
    ignored();
    return {
      kind: "ObjectValue",
      fields: v3
    };
  } else if (null != (a2 = l.exec(i))) {
    n = l.lastIndex;
    ignored();
    if (null != (r2 = a2[o.Const])) {
      return "null" === r2 ? {
        kind: "NullValue"
      } : {
        kind: "BooleanValue",
        value: "true" === r2
      };
    } else if (null != (r2 = a2[o.Var])) {
      if (e3) {
        throw error("Variable");
      } else {
        return {
          kind: "Variable",
          name: {
            kind: "Name",
            value: r2
          }
        };
      }
    } else if (null != (r2 = a2[o.Int])) {
      var s2;
      if (null != (s2 = a2[o.Float])) {
        return {
          kind: "FloatValue",
          value: r2 + s2
        };
      } else {
        return {
          kind: "IntValue",
          value: r2
        };
      }
    } else if (null != (r2 = a2[o.BlockString])) {
      return {
        kind: "StringValue",
        value: blockString(r2.slice(3, -3)),
        block: true
      };
    } else if (null != (r2 = a2[o.String])) {
      return {
        kind: "StringValue",
        value: u.test(r2) ? JSON.parse(r2) : r2.slice(1, -1),
        block: false
      };
    } else if (null != (r2 = a2[o.Enum])) {
      return {
        kind: "EnumValue",
        value: r2
      };
    }
  }
  throw error("Value");
}
function arguments_(e3) {
  if (40 === i.charCodeAt(n)) {
    var r2 = [];
    n++;
    ignored();
    var a2;
    do {
      if (null == (a2 = advance(t))) {
        throw error("Argument");
      }
      ignored();
      if (58 !== i.charCodeAt(n++)) {
        throw error("Argument");
      }
      ignored();
      r2.push({
        kind: "Argument",
        name: {
          kind: "Name",
          value: a2
        },
        value: value(e3)
      });
    } while (41 !== i.charCodeAt(n));
    n++;
    ignored();
    return r2;
  }
}
function directives(e3) {
  if (64 === i.charCodeAt(n)) {
    var r2 = [];
    var a2;
    do {
      n++;
      if (null == (a2 = advance(t))) {
        throw error("Directive");
      }
      ignored();
      r2.push({
        kind: "Directive",
        name: {
          kind: "Name",
          value: a2
        },
        arguments: arguments_(e3)
      });
    } while (64 === i.charCodeAt(n));
    return r2;
  }
}
function type() {
  var e3;
  var r2 = 0;
  while (91 === i.charCodeAt(n)) {
    r2++;
    n++;
    ignored();
  }
  if (null == (e3 = advance(t))) {
    throw error("NamedType");
  }
  ignored();
  var a2 = {
    kind: "NamedType",
    name: {
      kind: "Name",
      value: e3
    }
  };
  do {
    if (33 === i.charCodeAt(n)) {
      n++;
      ignored();
      a2 = {
        kind: "NonNullType",
        type: a2
      };
    }
    if (r2) {
      if (93 !== i.charCodeAt(n++)) {
        throw error("NamedType");
      }
      ignored();
      a2 = {
        kind: "ListType",
        type: a2
      };
    }
  } while (r2--);
  return a2;
}
var d = new RegExp("(?:(\\.{3})|(" + t.source + "))", "y");
var v = function(e3) {
  e3[e3.Spread = 1] = "Spread";
  e3[e3.Name = 2] = "Name";
  return e3;
}(v || {});
function selectionSet() {
  var e3 = [];
  var r2;
  var a2;
  do {
    d.lastIndex = n;
    if (null != (a2 = d.exec(i))) {
      n = d.lastIndex;
      if (null != a2[v.Spread]) {
        ignored();
        var l3 = advance(t);
        if (null != l3 && "on" !== l3) {
          ignored();
          e3.push({
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: l3
            },
            directives: directives(false)
          });
        } else {
          ignored();
          if ("on" === l3) {
            if (null == (l3 = advance(t))) {
              throw error("NamedType");
            }
            ignored();
          }
          var o2 = directives(false);
          if (123 !== i.charCodeAt(n++)) {
            throw error("InlineFragment");
          }
          ignored();
          e3.push({
            kind: "InlineFragment",
            typeCondition: l3 ? {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: l3
              }
            } : void 0,
            directives: o2,
            selectionSet: selectionSet()
          });
        }
      } else if (null != (r2 = a2[v.Name])) {
        var u3 = void 0;
        ignored();
        if (58 === i.charCodeAt(n)) {
          n++;
          ignored();
          u3 = r2;
          if (null == (r2 = advance(t))) {
            throw error("Field");
          }
          ignored();
        }
        var s2 = arguments_(false);
        ignored();
        var c2 = directives(false);
        var f3 = void 0;
        if (123 === i.charCodeAt(n)) {
          n++;
          ignored();
          f3 = selectionSet();
        }
        e3.push({
          kind: "Field",
          alias: u3 ? {
            kind: "Name",
            value: u3
          } : void 0,
          name: {
            kind: "Name",
            value: r2
          },
          arguments: s2,
          directives: c2,
          selectionSet: f3
        });
      }
    } else {
      throw error("SelectionSet");
    }
  } while (125 !== i.charCodeAt(n));
  n++;
  ignored();
  return {
    kind: "SelectionSet",
    selections: e3
  };
}
function fragmentDefinition() {
  var e3;
  var r2;
  if (null == (e3 = advance(t))) {
    throw error("FragmentDefinition");
  }
  ignored();
  if ("on" !== advance(t)) {
    throw error("FragmentDefinition");
  }
  ignored();
  if (null == (r2 = advance(t))) {
    throw error("FragmentDefinition");
  }
  ignored();
  var a2 = directives(false);
  if (123 !== i.charCodeAt(n++)) {
    throw error("FragmentDefinition");
  }
  ignored();
  return {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: e3
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: r2
      }
    },
    directives: a2,
    selectionSet: selectionSet()
  };
}
var s = /(?:query|mutation|subscription|fragment)/y;
function operationDefinition(e3) {
  var r2;
  var a2;
  var l3;
  if (e3) {
    ignored();
    r2 = advance(t);
    a2 = function variableDefinitions() {
      ignored();
      if (40 === i.charCodeAt(n)) {
        var e4 = [];
        n++;
        ignored();
        var r3;
        do {
          if (36 !== i.charCodeAt(n++)) {
            throw error("Variable");
          }
          if (null == (r3 = advance(t))) {
            throw error("Variable");
          }
          ignored();
          if (58 !== i.charCodeAt(n++)) {
            throw error("VariableDefinition");
          }
          ignored();
          var a3 = type();
          var l4 = void 0;
          if (61 === i.charCodeAt(n)) {
            n++;
            ignored();
            l4 = value(true);
          }
          ignored();
          e4.push({
            kind: "VariableDefinition",
            variable: {
              kind: "Variable",
              name: {
                kind: "Name",
                value: r3
              }
            },
            type: a3,
            defaultValue: l4,
            directives: directives(true)
          });
        } while (41 !== i.charCodeAt(n));
        n++;
        ignored();
        return e4;
      }
    }();
    l3 = directives(false);
  }
  if (123 === i.charCodeAt(n)) {
    n++;
    ignored();
    return {
      kind: "OperationDefinition",
      operation: e3 || "query",
      name: r2 ? {
        kind: "Name",
        value: r2
      } : void 0,
      variableDefinitions: a2,
      directives: l3,
      selectionSet: selectionSet()
    };
  }
}
function parse(e3, r2) {
  i = "string" == typeof e3.body ? e3.body : e3;
  n = 0;
  return function document2() {
    var e4;
    var r3;
    ignored();
    var a2 = [];
    do {
      if ("fragment" === (e4 = advance(s))) {
        ignored();
        a2.push(fragmentDefinition());
      } else if (null != (r3 = operationDefinition(e4))) {
        a2.push(r3);
      } else {
        throw error("Document");
      }
    } while (n < i.length);
    return {
      kind: "Document",
      definitions: a2
    };
  }();
}
function mapJoin(e3, r2, i3) {
  var n2 = "";
  for (var a2 = 0; a2 < e3.length; a2++) {
    if (a2) {
      n2 += r2;
    }
    n2 += i3(e3[a2]);
  }
  return n2;
}
function printString(e3) {
  return JSON.stringify(e3);
}
function printBlockString(e3) {
  return '"""\n' + e3.replace(/"""/g, '\\"""') + '\n"""';
}
var f = "\n";
var m = {
  OperationDefinition(e3) {
    var r2 = e3.operation;
    if (e3.name) {
      r2 += " " + e3.name.value;
    }
    if (e3.variableDefinitions && e3.variableDefinitions.length) {
      if (!e3.name) {
        r2 += " ";
      }
      r2 += "(" + mapJoin(e3.variableDefinitions, ", ", m.VariableDefinition) + ")";
    }
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    return "query" !== r2 ? r2 + " " + m.SelectionSet(e3.selectionSet) : m.SelectionSet(e3.selectionSet);
  },
  VariableDefinition(e3) {
    var r2 = m.Variable(e3.variable) + ": " + _print(e3.type);
    if (e3.defaultValue) {
      r2 += " = " + _print(e3.defaultValue);
    }
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    return r2;
  },
  Field(e3) {
    var r2 = e3.alias ? e3.alias.value + ": " + e3.name.value : e3.name.value;
    if (e3.arguments && e3.arguments.length) {
      var i3 = mapJoin(e3.arguments, ", ", m.Argument);
      if (r2.length + i3.length + 2 > 80) {
        r2 += "(" + (f += "  ") + mapJoin(e3.arguments, f, m.Argument) + (f = f.slice(0, -2)) + ")";
      } else {
        r2 += "(" + i3 + ")";
      }
    }
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    if (e3.selectionSet) {
      r2 += " " + m.SelectionSet(e3.selectionSet);
    }
    return r2;
  },
  StringValue(e3) {
    if (e3.block) {
      return printBlockString(e3.value).replace(/\n/g, f);
    } else {
      return printString(e3.value);
    }
  },
  BooleanValue: (e3) => "" + e3.value,
  NullValue: (e3) => "null",
  IntValue: (e3) => e3.value,
  FloatValue: (e3) => e3.value,
  EnumValue: (e3) => e3.value,
  Name: (e3) => e3.value,
  Variable: (e3) => "$" + e3.name.value,
  ListValue: (e3) => "[" + mapJoin(e3.values, ", ", _print) + "]",
  ObjectValue: (e3) => "{" + mapJoin(e3.fields, ", ", m.ObjectField) + "}",
  ObjectField: (e3) => e3.name.value + ": " + _print(e3.value),
  Document(e3) {
    if (!e3.definitions || !e3.definitions.length) {
      return "";
    }
    return mapJoin(e3.definitions, "\n\n", _print);
  },
  SelectionSet: (e3) => "{" + (f += "  ") + mapJoin(e3.selections, f, _print) + (f = f.slice(0, -2)) + "}",
  Argument: (e3) => e3.name.value + ": " + _print(e3.value),
  FragmentSpread(e3) {
    var r2 = "..." + e3.name.value;
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    return r2;
  },
  InlineFragment(e3) {
    var r2 = "...";
    if (e3.typeCondition) {
      r2 += " on " + e3.typeCondition.name.value;
    }
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    return r2 += " " + m.SelectionSet(e3.selectionSet);
  },
  FragmentDefinition(e3) {
    var r2 = "fragment " + e3.name.value;
    r2 += " on " + e3.typeCondition.name.value;
    if (e3.directives && e3.directives.length) {
      r2 += " " + mapJoin(e3.directives, " ", m.Directive);
    }
    return r2 + " " + m.SelectionSet(e3.selectionSet);
  },
  Directive(e3) {
    var r2 = "@" + e3.name.value;
    if (e3.arguments && e3.arguments.length) {
      r2 += "(" + mapJoin(e3.arguments, ", ", m.Argument) + ")";
    }
    return r2;
  },
  NamedType: (e3) => e3.name.value,
  ListType: (e3) => "[" + _print(e3.type) + "]",
  NonNullType: (e3) => _print(e3.type) + "!"
};
var _print = (e3) => m[e3.kind](e3);
function print(e3) {
  f = "\n";
  return m[e3.kind] ? m[e3.kind](e3) : "";
}

// ../../node_modules/wonka/dist/wonka.mjs
var teardownPlaceholder = () => {
};
var e2 = teardownPlaceholder;
function start(e3) {
  return {
    tag: 0,
    0: e3
  };
}
function push(e3) {
  return {
    tag: 1,
    0: e3
  };
}
var asyncIteratorSymbol = () => "function" == typeof Symbol && Symbol.asyncIterator || "@@asyncIterator";
var identity = (e3) => e3;
function filter(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    t2((e3) => {
      if (0 === e3) {
        i3(0);
      } else if (0 === e3.tag) {
        a2 = e3[0];
        i3(e3);
      } else if (!r2(e3[0])) {
        a2(0);
      } else {
        i3(e3);
      }
    });
  };
}
function map(e3) {
  return (r2) => (t2) => r2((r3) => {
    if (0 === r3 || 0 === r3.tag) {
      t2(r3);
    } else {
      t2(push(e3(r3[0])));
    }
  });
}
function mergeMap(r2) {
  return (t2) => (i3) => {
    var a2 = [];
    var f3 = e2;
    var n2 = false;
    var s2 = false;
    t2((t3) => {
      if (s2) {
      } else if (0 === t3) {
        s2 = true;
        if (!a2.length) {
          i3(0);
        }
      } else if (0 === t3.tag) {
        f3 = t3[0];
      } else {
        n2 = false;
        !function applyInnerSource(r3) {
          var t4 = e2;
          r3((e3) => {
            if (0 === e3) {
              if (a2.length) {
                var r4 = a2.indexOf(t4);
                if (r4 > -1) {
                  (a2 = a2.slice()).splice(r4, 1);
                }
                if (!a2.length) {
                  if (s2) {
                    i3(0);
                  } else if (!n2) {
                    n2 = true;
                    f3(0);
                  }
                }
              }
            } else if (0 === e3.tag) {
              a2.push(t4 = e3[0]);
              t4(0);
            } else if (a2.length) {
              i3(e3);
              t4(0);
            }
          });
        }(r2(t3[0]));
        if (!n2) {
          n2 = true;
          f3(0);
        }
      }
    });
    i3(start((e3) => {
      if (1 === e3) {
        if (!s2) {
          s2 = true;
          f3(1);
        }
        for (var r3 = 0, t3 = a2, i4 = a2.length; r3 < i4; r3++) {
          t3[r3](1);
        }
        a2.length = 0;
      } else {
        if (!s2 && !n2) {
          n2 = true;
          f3(0);
        } else {
          n2 = false;
        }
        for (var l3 = 0, u3 = a2, o2 = a2.length; l3 < o2; l3++) {
          u3[l3](0);
        }
      }
    }));
  };
}
function mergeAll(e3) {
  return mergeMap(identity)(e3);
}
function merge(e3) {
  return mergeAll(r(e3));
}
function onEnd(e3) {
  return (r2) => (t2) => {
    var i3 = false;
    r2((r3) => {
      if (i3) {
      } else if (0 === r3) {
        i3 = true;
        t2(0);
        e3();
      } else if (0 === r3.tag) {
        var a2 = r3[0];
        t2(start((r4) => {
          if (1 === r4) {
            i3 = true;
            a2(1);
            e3();
          } else {
            a2(r4);
          }
        }));
      } else {
        t2(r3);
      }
    });
  };
}
function onPush(e3) {
  return (r2) => (t2) => {
    var i3 = false;
    r2((r3) => {
      if (i3) {
      } else if (0 === r3) {
        i3 = true;
        t2(0);
      } else if (0 === r3.tag) {
        var a2 = r3[0];
        t2(start((e4) => {
          if (1 === e4) {
            i3 = true;
          }
          a2(e4);
        }));
      } else {
        e3(r3[0]);
        t2(r3);
      }
    });
  };
}
function onStart(e3) {
  return (r2) => (t2) => r2((r3) => {
    if (0 === r3) {
      t2(0);
    } else if (0 === r3.tag) {
      t2(r3);
      e3();
    } else {
      t2(r3);
    }
  });
}
function share(r2) {
  var t2 = [];
  var i3 = e2;
  var a2 = false;
  return (e3) => {
    t2.push(e3);
    if (1 === t2.length) {
      r2((e4) => {
        if (0 === e4) {
          for (var r3 = 0, f3 = t2, n2 = t2.length; r3 < n2; r3++) {
            f3[r3](0);
          }
          t2.length = 0;
        } else if (0 === e4.tag) {
          i3 = e4[0];
        } else {
          a2 = false;
          for (var s2 = 0, l3 = t2, u3 = t2.length; s2 < u3; s2++) {
            l3[s2](e4);
          }
        }
      });
    }
    e3(start((r3) => {
      if (1 === r3) {
        var f3 = t2.indexOf(e3);
        if (f3 > -1) {
          (t2 = t2.slice()).splice(f3, 1);
        }
        if (!t2.length) {
          i3(1);
        }
      } else if (!a2) {
        a2 = true;
        i3(0);
      }
    }));
  };
}
function switchMap(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = e2;
    var n2 = false;
    var s2 = false;
    var l3 = false;
    var u3 = false;
    t2((t3) => {
      if (u3) {
      } else if (0 === t3) {
        u3 = true;
        if (!l3) {
          i3(0);
        }
      } else if (0 === t3.tag) {
        a2 = t3[0];
      } else {
        if (l3) {
          f3(1);
          f3 = e2;
        }
        if (!n2) {
          n2 = true;
          a2(0);
        } else {
          n2 = false;
        }
        !function applyInnerSource(e3) {
          l3 = true;
          e3((e4) => {
            if (!l3) {
            } else if (0 === e4) {
              l3 = false;
              if (u3) {
                i3(0);
              } else if (!n2) {
                n2 = true;
                a2(0);
              }
            } else if (0 === e4.tag) {
              s2 = false;
              (f3 = e4[0])(0);
            } else {
              i3(e4);
              if (!s2) {
                f3(0);
              } else {
                s2 = false;
              }
            }
          });
        }(r2(t3[0]));
      }
    });
    i3(start((e3) => {
      if (1 === e3) {
        if (!u3) {
          u3 = true;
          a2(1);
        }
        if (l3) {
          l3 = false;
          f3(1);
        }
      } else {
        if (!u3 && !n2) {
          n2 = true;
          a2(0);
        }
        if (l3 && !s2) {
          s2 = true;
          f3(0);
        }
      }
    }));
  };
}
function take(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = false;
    var n2 = 0;
    t2((e3) => {
      if (f3) {
      } else if (0 === e3) {
        f3 = true;
        i3(0);
      } else if (0 === e3.tag) {
        if (r2 <= 0) {
          f3 = true;
          i3(0);
          e3[0](1);
        } else {
          a2 = e3[0];
        }
      } else if (n2++ < r2) {
        i3(e3);
        if (!f3 && n2 >= r2) {
          f3 = true;
          i3(0);
          a2(1);
        }
      } else {
        i3(e3);
      }
    });
    i3(start((e3) => {
      if (1 === e3 && !f3) {
        f3 = true;
        a2(1);
      } else if (0 === e3 && !f3 && n2 < r2) {
        a2(0);
      }
    }));
  };
}
function takeUntil(r2) {
  return (t2) => (i3) => {
    var a2 = e2;
    var f3 = e2;
    var n2 = false;
    t2((e3) => {
      if (n2) {
      } else if (0 === e3) {
        n2 = true;
        f3(1);
        i3(0);
      } else if (0 === e3.tag) {
        a2 = e3[0];
        r2((e4) => {
          if (0 === e4) {
          } else if (0 === e4.tag) {
            (f3 = e4[0])(0);
          } else {
            n2 = true;
            f3(1);
            a2(1);
            i3(0);
          }
        });
      } else {
        i3(e3);
      }
    });
    i3(start((e3) => {
      if (1 === e3 && !n2) {
        n2 = true;
        a2(1);
        f3(1);
      } else if (!n2) {
        a2(0);
      }
    }));
  };
}
function takeWhile(r2, t2) {
  return (i3) => (a2) => {
    var f3 = e2;
    var n2 = false;
    i3((e3) => {
      if (n2) {
      } else if (0 === e3) {
        n2 = true;
        a2(0);
      } else if (0 === e3.tag) {
        f3 = e3[0];
        a2(e3);
      } else if (!r2(e3[0])) {
        n2 = true;
        if (t2) {
          a2(e3);
        }
        a2(0);
        f3(1);
      } else {
        a2(e3);
      }
    });
  };
}
function lazy(e3) {
  return (r2) => e3()(r2);
}
function fromAsyncIterable(e3) {
  return (r2) => {
    var t2 = e3[asyncIteratorSymbol()] && e3[asyncIteratorSymbol()]() || e3;
    var i3 = false;
    var a2 = false;
    var f3 = false;
    var n2;
    r2(start(async (e4) => {
      if (1 === e4) {
        i3 = true;
        if (t2.return) {
          t2.return();
        }
      } else if (a2) {
        f3 = true;
      } else {
        for (f3 = a2 = true; f3 && !i3; ) {
          if ((n2 = await t2.next()).done) {
            i3 = true;
            if (t2.return) {
              await t2.return();
            }
            r2(0);
          } else {
            try {
              f3 = false;
              r2(push(n2.value));
            } catch (e5) {
              if (t2.throw) {
                if (i3 = !!(await t2.throw(e5)).done) {
                  r2(0);
                }
              } else {
                throw e5;
              }
            }
          }
        }
        a2 = false;
      }
    }));
  };
}
function fromIterable(e3) {
  if (e3[Symbol.asyncIterator]) {
    return fromAsyncIterable(e3);
  }
  return (r2) => {
    var t2 = e3[Symbol.iterator]();
    var i3 = false;
    var a2 = false;
    var f3 = false;
    var n2;
    r2(start((e4) => {
      if (1 === e4) {
        i3 = true;
        if (t2.return) {
          t2.return();
        }
      } else if (a2) {
        f3 = true;
      } else {
        for (f3 = a2 = true; f3 && !i3; ) {
          if ((n2 = t2.next()).done) {
            i3 = true;
            if (t2.return) {
              t2.return();
            }
            r2(0);
          } else {
            try {
              f3 = false;
              r2(push(n2.value));
            } catch (e5) {
              if (t2.throw) {
                if (i3 = !!t2.throw(e5).done) {
                  r2(0);
                }
              } else {
                throw e5;
              }
            }
          }
        }
        a2 = false;
      }
    }));
  };
}
var r = fromIterable;
function fromValue(e3) {
  return (r2) => {
    var t2 = false;
    r2(start((i3) => {
      if (1 === i3) {
        t2 = true;
      } else if (!t2) {
        t2 = true;
        r2(push(e3));
        r2(0);
      }
    }));
  };
}
function make(e3) {
  return (r2) => {
    var t2 = false;
    var i3 = e3({
      next(e4) {
        if (!t2) {
          r2(push(e4));
        }
      },
      complete() {
        if (!t2) {
          t2 = true;
          r2(0);
        }
      }
    });
    r2(start((e4) => {
      if (1 === e4 && !t2) {
        t2 = true;
        i3();
      }
    }));
  };
}
function makeSubject() {
  var e3;
  var r2;
  return {
    source: share(make((t2) => {
      e3 = t2.next;
      r2 = t2.complete;
      return teardownPlaceholder;
    })),
    next(r3) {
      if (e3) {
        e3(r3);
      }
    },
    complete() {
      if (r2) {
        r2();
      }
    }
  };
}
function subscribe(r2) {
  return (t2) => {
    var i3 = e2;
    var a2 = false;
    t2((e3) => {
      if (0 === e3) {
        a2 = true;
      } else if (0 === e3.tag) {
        (i3 = e3[0])(0);
      } else if (!a2) {
        r2(e3[0]);
        i3(0);
      }
    });
    return {
      unsubscribe() {
        if (!a2) {
          a2 = true;
          i3(1);
        }
      }
    };
  };
}
function publish(e3) {
  subscribe((e4) => {
  })(e3);
}
function toPromise(r2) {
  return new Promise((t2) => {
    var i3 = e2;
    var a2;
    r2((e3) => {
      if (0 === e3) {
        Promise.resolve(a2).then(t2);
      } else if (0 === e3.tag) {
        (i3 = e3[0])(0);
      } else {
        a2 = e3[0];
        i3(0);
      }
    });
  });
}

// ../../node_modules/@urql/core/dist/urql-core-chunk.mjs
var rehydrateGraphQlError = (r2) => {
  if (r2 && r2.message && (r2.extensions || "GraphQLError" === r2.name)) {
    return r2;
  } else if ("object" == typeof r2 && r2.message) {
    return new GraphQLError(r2.message, r2.nodes, r2.source, r2.positions, r2.path, r2, r2.extensions || {});
  } else {
    return new GraphQLError(r2);
  }
};
var CombinedError = class extends Error {
  constructor(e3) {
    var r2 = (e3.graphQLErrors || []).map(rehydrateGraphQlError);
    var t2 = ((e4, r3) => {
      var t3 = "";
      if (e4) {
        return `[Network] ${e4.message}`;
      }
      if (r3) {
        for (var a2 of r3) {
          if (t3) {
            t3 += "\n";
          }
          t3 += `[GraphQL] ${a2.message}`;
        }
      }
      return t3;
    })(e3.networkError, r2);
    super(t2);
    this.name = "CombinedError";
    this.message = t2;
    this.graphQLErrors = r2;
    this.networkError = e3.networkError;
    this.response = e3.response;
  }
  toString() {
    return this.message;
  }
};
var phash = (e3, r2) => {
  var t2 = 0 | (r2 || 5381);
  for (var a2 = 0, o2 = 0 | e3.length; a2 < o2; a2++) {
    t2 = (t2 << 5) + t2 + e3.charCodeAt(a2);
  }
  return t2;
};
var i2 = /* @__PURE__ */ new Set();
var f2 = /* @__PURE__ */ new WeakMap();
var stringify = (e3, r2) => {
  if (null === e3 || i2.has(e3)) {
    return "null";
  } else if ("object" != typeof e3) {
    return JSON.stringify(e3) || "";
  } else if (e3.toJSON) {
    return stringify(e3.toJSON(), r2);
  } else if (Array.isArray(e3)) {
    var t2 = "[";
    for (var a2 of e3) {
      if (t2.length > 1) {
        t2 += ",";
      }
      t2 += stringify(a2, r2) || "null";
    }
    return t2 += "]";
  } else if (!r2 && (l2 !== NoopConstructor && e3 instanceof l2 || c !== NoopConstructor && e3 instanceof c)) {
    return "null";
  }
  var o2 = Object.keys(e3).sort();
  if (!o2.length && e3.constructor && Object.getPrototypeOf(e3).constructor !== Object.prototype.constructor) {
    var n2 = f2.get(e3) || Math.random().toString(36).slice(2);
    f2.set(e3, n2);
    return stringify({
      __key: n2
    }, r2);
  }
  i2.add(e3);
  var s2 = "{";
  for (var d3 of o2) {
    var v3 = stringify(e3[d3], r2);
    if (v3) {
      if (s2.length > 1) {
        s2 += ",";
      }
      s2 += stringify(d3, r2) + ":" + v3;
    }
  }
  i2.delete(e3);
  return s2 += "}";
};
var extract = (e3, r2, t2) => {
  if (null == t2 || "object" != typeof t2 || t2.toJSON || i2.has(t2)) {
  } else if (Array.isArray(t2)) {
    for (var a2 = 0, o2 = t2.length; a2 < o2; a2++) {
      extract(e3, `${r2}.${a2}`, t2[a2]);
    }
  } else if (t2 instanceof l2 || t2 instanceof c) {
    e3.set(r2, t2);
  } else {
    i2.add(t2);
    for (var n2 of Object.keys(t2)) {
      extract(e3, `${r2}.${n2}`, t2[n2]);
    }
  }
};
var stringifyVariables = (e3, r2) => {
  i2.clear();
  return stringify(e3, r2 || false);
};
var NoopConstructor = class {
};
var l2 = "undefined" != typeof File ? File : NoopConstructor;
var c = "undefined" != typeof Blob ? Blob : NoopConstructor;
var d2 = /("{3}[\s\S]*"{3}|"(?:\\.|[^"])*")/g;
var v2 = /(?:#[^\n\r]+)?(?:[\r\n]+|$)/g;
var replaceOutsideStrings = (e3, r2) => r2 % 2 == 0 ? e3.replace(v2, "\n") : e3;
var sanitizeDocument = (e3) => e3.split(d2).map(replaceOutsideStrings).join("").trim();
var p = /* @__PURE__ */ new Map();
var u2 = /* @__PURE__ */ new Map();
var stringifyDocument = (e3) => {
  var t2;
  if ("string" == typeof e3) {
    t2 = sanitizeDocument(e3);
  } else if (e3.loc && u2.get(e3.__key) === e3) {
    t2 = e3.loc.source.body;
  } else {
    t2 = p.get(e3) || sanitizeDocument(print(e3));
    p.set(e3, t2);
  }
  if ("string" != typeof e3 && !e3.loc) {
    e3.loc = {
      start: 0,
      end: t2.length,
      source: {
        body: t2,
        name: "gql",
        locationOffset: {
          line: 1,
          column: 1
        }
      }
    };
  }
  return t2;
};
var hashDocument = (e3) => {
  var r2;
  if (e3.documentId) {
    r2 = phash(e3.documentId);
  } else {
    r2 = phash(stringifyDocument(e3));
    if (e3.definitions) {
      var t2 = getOperationName(e3);
      if (t2) {
        r2 = phash(`
# ${t2}`, r2);
      }
    }
  }
  return r2;
};
var keyDocument = (e3) => {
  var r2;
  var a2;
  if ("string" == typeof e3) {
    r2 = hashDocument(e3);
    a2 = u2.get(r2) || parse(e3, {
      noLocation: true
    });
  } else {
    r2 = e3.__key || hashDocument(e3);
    a2 = u2.get(r2) || e3;
  }
  if (!a2.loc) {
    stringifyDocument(a2);
  }
  a2.__key = r2;
  u2.set(r2, a2);
  return a2;
};
var createRequest = (e3, r2, t2) => {
  var a2 = r2 || {};
  var o2 = keyDocument(e3);
  var n2 = stringifyVariables(a2, true);
  var s2 = o2.__key;
  if ("{}" !== n2) {
    s2 = phash(n2, s2);
  }
  return {
    key: s2,
    query: o2,
    variables: a2,
    extensions: t2
  };
};
var getOperationName = (e3) => {
  for (var r2 of e3.definitions) {
    if (r2.kind === e.OPERATION_DEFINITION) {
      return r2.name ? r2.name.value : void 0;
    }
  }
};
var getOperationType = (e3) => {
  for (var r2 of e3.definitions) {
    if (r2.kind === e.OPERATION_DEFINITION) {
      return r2.operation;
    }
  }
};
var makeResult = (e3, r2, t2) => {
  if (!("data" in r2 || "errors" in r2 && Array.isArray(r2.errors))) {
    throw new Error("No Content");
  }
  var a2 = "subscription" === e3.kind;
  return {
    operation: e3,
    data: r2.data,
    error: Array.isArray(r2.errors) ? new CombinedError({
      graphQLErrors: r2.errors,
      response: t2
    }) : void 0,
    extensions: r2.extensions ? {
      ...r2.extensions
    } : void 0,
    hasNext: null == r2.hasNext ? a2 : r2.hasNext,
    stale: false
  };
};
var deepMerge = (e3, r2) => {
  if ("object" == typeof e3 && null != e3) {
    if (!e3.constructor || e3.constructor === Object || Array.isArray(e3)) {
      e3 = Array.isArray(e3) ? [...e3] : {
        ...e3
      };
      for (var t2 of Object.keys(r2)) {
        e3[t2] = deepMerge(e3[t2], r2[t2]);
      }
      return e3;
    }
  }
  return r2;
};
var mergeResultPatch = (e3, r2, t2, a2) => {
  var o2 = e3.error ? e3.error.graphQLErrors : [];
  var n2 = !!e3.extensions || !!(r2.payload || r2).extensions;
  var s2 = {
    ...e3.extensions,
    ...(r2.payload || r2).extensions
  };
  var i3 = r2.incremental;
  if ("path" in r2) {
    i3 = [r2];
  }
  var f3 = {
    data: e3.data
  };
  if (i3) {
    var _loop = function(e4) {
      if (Array.isArray(e4.errors)) {
        o2.push(...e4.errors);
      }
      if (e4.extensions) {
        Object.assign(s2, e4.extensions);
        n2 = true;
      }
      var r3 = "data";
      var t3 = f3;
      var i4 = [];
      if (e4.path) {
        i4 = e4.path;
      } else if (a2) {
        var l4 = a2.find((r4) => r4.id === e4.id);
        if (e4.subPath) {
          i4 = [...l4.path, ...e4.subPath];
        } else {
          i4 = l4.path;
        }
      }
      for (var c2 = 0, d3 = i4.length; c2 < d3; r3 = i4[c2++]) {
        t3 = t3[r3] = Array.isArray(t3[r3]) ? [...t3[r3]] : {
          ...t3[r3]
        };
      }
      if (e4.items) {
        var v3 = +r3 >= 0 ? r3 : 0;
        for (var p2 = 0, u3 = e4.items.length; p2 < u3; p2++) {
          t3[v3 + p2] = deepMerge(t3[v3 + p2], e4.items[p2]);
        }
      } else if (void 0 !== e4.data) {
        t3[r3] = deepMerge(t3[r3], e4.data);
      }
    };
    for (var l3 of i3) {
      _loop(l3);
    }
  } else {
    f3.data = (r2.payload || r2).data || e3.data;
    o2 = r2.errors || r2.payload && r2.payload.errors || o2;
  }
  return {
    operation: e3.operation,
    data: f3.data,
    error: o2.length ? new CombinedError({
      graphQLErrors: o2,
      response: t2
    }) : void 0,
    extensions: n2 ? s2 : void 0,
    hasNext: null != r2.hasNext ? r2.hasNext : e3.hasNext,
    stale: false
  };
};
var makeErrorResult = (e3, r2, t2) => ({
  operation: e3,
  data: void 0,
  error: new CombinedError({
    networkError: r2,
    response: t2
  }),
  extensions: void 0,
  hasNext: false,
  stale: false
});
function makeFetchBody(e3) {
  var r2 = {
    query: void 0,
    documentId: void 0,
    operationName: getOperationName(e3.query),
    variables: e3.variables || void 0,
    extensions: e3.extensions
  };
  if ("documentId" in e3.query && e3.query.documentId && (!e3.query.definitions || !e3.query.definitions.length)) {
    r2.documentId = e3.query.documentId;
  } else if (!e3.extensions || !e3.extensions.persistedQuery || e3.extensions.persistedQuery.miss) {
    r2.query = stringifyDocument(e3.query);
  }
  return r2;
}
var makeFetchURL = (e3, r2) => {
  var t2 = "query" === e3.kind && e3.context.preferGetMethod;
  if (!t2 || !r2) {
    return e3.context.url;
  }
  var a2 = splitOutSearchParams(e3.context.url);
  for (var o2 in r2) {
    var n2 = r2[o2];
    if (n2) {
      a2[1].set(o2, "object" == typeof n2 ? stringifyVariables(n2) : n2);
    }
  }
  var s2 = a2.join("?");
  if (s2.length > 2047 && "force" !== t2) {
    e3.context.preferGetMethod = false;
    return e3.context.url;
  }
  return s2;
};
var splitOutSearchParams = (e3) => {
  var r2 = e3.indexOf("?");
  return r2 > -1 ? [e3.slice(0, r2), new URLSearchParams(e3.slice(r2 + 1))] : [e3, new URLSearchParams()];
};
var serializeBody = (e3, r2) => {
  if (r2 && !("query" === e3.kind && !!e3.context.preferGetMethod)) {
    var t2 = stringifyVariables(r2);
    var a2 = ((e4) => {
      var r3 = /* @__PURE__ */ new Map();
      if (l2 !== NoopConstructor || c !== NoopConstructor) {
        i2.clear();
        extract(r3, "variables", e4);
      }
      return r3;
    })(r2.variables);
    if (a2.size) {
      var o2 = new FormData();
      o2.append("operations", t2);
      o2.append("map", stringifyVariables({
        ...[...a2.keys()].map((e4) => [e4])
      }));
      var n2 = 0;
      for (var s2 of a2.values()) {
        o2.append("" + n2++, s2);
      }
      return o2;
    }
    return t2;
  }
};
var makeFetchOptions = (e3, r2) => {
  var t2 = {
    accept: "subscription" === e3.kind ? "text/event-stream, multipart/mixed" : "application/graphql-response+json, application/graphql+json, application/json, text/event-stream, multipart/mixed"
  };
  var a2 = ("function" == typeof e3.context.fetchOptions ? e3.context.fetchOptions() : e3.context.fetchOptions) || {};
  if (a2.headers) {
    if (((e4) => "has" in e4 && !Object.keys(e4).length)(a2.headers)) {
      a2.headers.forEach((e4, r3) => {
        t2[r3] = e4;
      });
    } else if (Array.isArray(a2.headers)) {
      a2.headers.forEach((e4, r3) => {
        if (Array.isArray(e4)) {
          if (t2[e4[0]]) {
            t2[e4[0]] = `${t2[e4[0]]},${e4[1]}`;
          } else {
            t2[e4[0]] = e4[1];
          }
        } else {
          t2[r3] = e4;
        }
      });
    } else {
      for (var o2 in a2.headers) {
        t2[o2.toLowerCase()] = a2.headers[o2];
      }
    }
  }
  var n2 = serializeBody(e3, r2);
  if ("string" == typeof n2 && !t2["content-type"]) {
    t2["content-type"] = "application/json";
  }
  return {
    ...a2,
    method: n2 ? "POST" : "GET",
    body: n2,
    headers: t2
  };
};
var y = "undefined" != typeof TextDecoder ? new TextDecoder() : null;
var h = /boundary="?([^=";]+)"?/i;
var m2 = /data: ?([^\n]+)/;
var toString = (e3) => "Buffer" === e3.constructor.name ? e3.toString() : y.decode(e3);
async function* streamBody(e3) {
  if (e3.body[Symbol.asyncIterator]) {
    for await (var r2 of e3.body) {
      yield toString(r2);
    }
  } else {
    var t2 = e3.body.getReader();
    var a2;
    try {
      while (!(a2 = await t2.read()).done) {
        yield toString(a2.value);
      }
    } finally {
      t2.cancel();
    }
  }
}
async function* split(e3, r2) {
  var t2 = "";
  var a2;
  for await (var o2 of e3) {
    t2 += o2;
    while ((a2 = t2.indexOf(r2)) > -1) {
      yield t2.slice(0, a2);
      t2 = t2.slice(a2 + r2.length);
    }
  }
}
async function* fetchOperation(e3, r2, t2) {
  var a2 = true;
  var o2 = null;
  var n2;
  try {
    yield await Promise.resolve();
    var s2 = (n2 = await (e3.context.fetch || fetch)(r2, t2)).headers.get("Content-Type") || "";
    var i3;
    if (/multipart\/mixed/i.test(s2)) {
      i3 = async function* parseMultipartMixed(e4, r3) {
        var t3 = e4.match(h);
        var a3 = "--" + (t3 ? t3[1] : "-");
        var o3 = true;
        var n3;
        for await (var s3 of split(streamBody(r3), "\r\n" + a3)) {
          if (o3) {
            o3 = false;
            var i4 = s3.indexOf(a3);
            if (i4 > -1) {
              s3 = s3.slice(i4 + a3.length);
            } else {
              continue;
            }
          }
          try {
            yield n3 = JSON.parse(s3.slice(s3.indexOf("\r\n\r\n") + 4));
          } catch (e5) {
            if (!n3) {
              throw e5;
            }
          }
          if (n3 && false === n3.hasNext) {
            break;
          }
        }
        if (n3 && false !== n3.hasNext) {
          yield {
            hasNext: false
          };
        }
      }(s2, n2);
    } else if (/text\/event-stream/i.test(s2)) {
      i3 = async function* parseEventStream(e4) {
        var r3;
        for await (var t3 of split(streamBody(e4), "\n\n")) {
          var a3 = t3.match(m2);
          if (a3) {
            var o3 = a3[1];
            try {
              yield r3 = JSON.parse(o3);
            } catch (e5) {
              if (!r3) {
                throw e5;
              }
            }
            if (r3 && false === r3.hasNext) {
              break;
            }
          }
        }
        if (r3 && false !== r3.hasNext) {
          yield {
            hasNext: false
          };
        }
      }(n2);
    } else if (!/text\//i.test(s2)) {
      i3 = async function* parseJSON(e4) {
        yield JSON.parse(await e4.text());
      }(n2);
    } else {
      i3 = async function* parseMaybeJSON(e4) {
        var r3 = await e4.text();
        try {
          var t3 = JSON.parse(r3);
          if ("production" !== process.env.NODE_ENV) {
            console.warn('Found response with content-type "text/plain" but it had a valid "application/json" response.');
          }
          yield t3;
        } catch (e5) {
          throw new Error(r3);
        }
      }(n2);
    }
    var f3;
    for await (var l3 of i3) {
      if (l3.pending && !o2) {
        f3 = l3.pending;
      } else if (l3.pending) {
        f3 = [...f3, ...l3.pending];
      }
      o2 = o2 ? mergeResultPatch(o2, l3, n2, f3) : makeResult(e3, l3, n2);
      a2 = false;
      yield o2;
      a2 = true;
    }
    if (!o2) {
      yield o2 = makeResult(e3, {}, n2);
    }
  } catch (r3) {
    if (!a2) {
      throw r3;
    }
    yield makeErrorResult(e3, n2 && (n2.status < 200 || n2.status >= 300) && n2.statusText ? new Error(n2.statusText) : r3, n2);
  }
}
function makeFetchSource(e3, r2, t2) {
  var a2;
  if ("undefined" != typeof AbortController) {
    t2.signal = (a2 = new AbortController()).signal;
  }
  return onEnd(() => {
    if (a2) {
      a2.abort();
    }
  })(filter((e4) => !!e4)(fromAsyncIterable(fetchOperation(e3, r2, t2))));
}

// ../../node_modules/@urql/core/dist/urql-core.mjs
var collectTypes = (e3, r2) => {
  if (Array.isArray(e3)) {
    for (var t2 of e3) {
      collectTypes(t2, r2);
    }
  } else if ("object" == typeof e3 && null !== e3) {
    for (var n2 in e3) {
      if ("__typename" === n2 && "string" == typeof e3[n2]) {
        r2.add(e3[n2]);
      } else {
        collectTypes(e3[n2], r2);
      }
    }
  }
  return r2;
};
var formatNode = (r2) => {
  if ("definitions" in r2) {
    var t2 = [];
    for (var n2 of r2.definitions) {
      var a2 = formatNode(n2);
      t2.push(a2);
    }
    return {
      ...r2,
      definitions: t2
    };
  }
  if ("directives" in r2 && r2.directives && r2.directives.length) {
    var o2 = [];
    var i3 = {};
    for (var s2 of r2.directives) {
      var c2 = s2.name.value;
      if ("_" !== c2[0]) {
        o2.push(s2);
      } else {
        c2 = c2.slice(1);
      }
      i3[c2] = s2;
    }
    r2 = {
      ...r2,
      directives: o2,
      _directives: i3
    };
  }
  if ("selectionSet" in r2) {
    var u3 = [];
    var p2 = r2.kind === e.OPERATION_DEFINITION;
    if (r2.selectionSet) {
      for (var d3 of r2.selectionSet.selections || []) {
        p2 = p2 || d3.kind === e.FIELD && "__typename" === d3.name.value && !d3.alias;
        var v3 = formatNode(d3);
        u3.push(v3);
      }
      if (!p2) {
        u3.push({
          kind: e.FIELD,
          name: {
            kind: e.NAME,
            value: "__typename"
          },
          _generated: true
        });
      }
      return {
        ...r2,
        selectionSet: {
          ...r2.selectionSet,
          selections: u3
        }
      };
    }
  }
  return r2;
};
var I = /* @__PURE__ */ new Map();
var formatDocument = (e3) => {
  var t2 = keyDocument(e3);
  var n2 = I.get(t2.__key);
  if (!n2) {
    I.set(t2.__key, n2 = formatNode(t2));
    Object.defineProperty(n2, "__key", {
      value: t2.__key,
      enumerable: false
    });
  }
  return n2;
};
function withPromise(e3) {
  var source$ = (r2) => e3(r2);
  source$.toPromise = () => toPromise(take(1)(filter((e4) => !e4.stale && !e4.hasNext)(source$)));
  source$.then = (e4, r2) => source$.toPromise().then(e4, r2);
  source$.subscribe = (e4) => subscribe(e4)(source$);
  return source$;
}
function makeOperation(e3, r2, t2) {
  return {
    ...r2,
    kind: e3,
    context: r2.context ? {
      ...r2.context,
      ...t2
    } : t2 || r2.context
  };
}
var addMetadata = (e3, r2) => makeOperation(e3.kind, e3, {
  meta: {
    ...e3.context.meta,
    ...r2
  }
});
var noop = () => {
};
var shouldSkip = ({ kind: e3 }) => "mutation" !== e3 && "query" !== e3;
var mapTypeNames = (e3) => {
  var r2 = formatDocument(e3.query);
  if (r2 !== e3.query) {
    var t2 = makeOperation(e3.kind, e3);
    t2.query = r2;
    return t2;
  } else {
    return e3;
  }
};
var cacheExchange = ({ forward: e3, client: r2, dispatchDebug: t2 }) => {
  var a2 = /* @__PURE__ */ new Map();
  var o2 = /* @__PURE__ */ new Map();
  var isOperationCached = (e4) => "query" === e4.kind && "network-only" !== e4.context.requestPolicy && ("cache-only" === e4.context.requestPolicy || a2.has(e4.key));
  return (i3) => {
    var s2 = map((e4) => {
      var o3 = a2.get(e4.key);
      "production" !== process.env.NODE_ENV && t2({
        operation: e4,
        ...o3 ? {
          type: "cacheHit",
          message: "The result was successfully retried from the cache"
        } : {
          type: "cacheMiss",
          message: "The result could not be retrieved from the cache"
        },
        source: "cacheExchange"
      });
      var i4 = o3 || makeResult(e4, {
        data: null
      });
      i4 = {
        ...i4,
        operation: "production" !== process.env.NODE_ENV ? addMetadata(e4, {
          cacheOutcome: o3 ? "hit" : "miss"
        }) : e4
      };
      if ("cache-and-network" === e4.context.requestPolicy) {
        i4.stale = true;
        reexecuteOperation(r2, e4);
      }
      return i4;
    })(filter((e4) => !shouldSkip(e4) && isOperationCached(e4))(i3));
    var c2 = onPush((e4) => {
      var { operation: n2 } = e4;
      if (!n2) {
        return;
      }
      var i4 = n2.context.additionalTypenames || [];
      if ("subscription" !== e4.operation.kind) {
        i4 = ((e5) => [...collectTypes(e5, /* @__PURE__ */ new Set())])(e4.data).concat(i4);
      }
      if ("mutation" === e4.operation.kind || "subscription" === e4.operation.kind) {
        var s3 = /* @__PURE__ */ new Set();
        "production" !== process.env.NODE_ENV && t2({
          type: "cacheInvalidation",
          message: `The following typenames have been invalidated: ${i4}`,
          operation: n2,
          data: {
            typenames: i4,
            response: e4
          },
          source: "cacheExchange"
        });
        for (var c3 = 0; c3 < i4.length; c3++) {
          var u3 = i4[c3];
          var p2 = o2.get(u3);
          if (!p2) {
            o2.set(u3, p2 = /* @__PURE__ */ new Set());
          }
          for (var d3 of p2.values()) {
            s3.add(d3);
          }
          p2.clear();
        }
        for (var v3 of s3.values()) {
          if (a2.has(v3)) {
            n2 = a2.get(v3).operation;
            a2.delete(v3);
            reexecuteOperation(r2, n2);
          }
        }
      } else if ("query" === n2.kind && e4.data) {
        a2.set(n2.key, e4);
        for (var l3 = 0; l3 < i4.length; l3++) {
          var f3 = i4[l3];
          var h2 = o2.get(f3);
          if (!h2) {
            o2.set(f3, h2 = /* @__PURE__ */ new Set());
          }
          h2.add(n2.key);
        }
      }
    })(e3(filter((e4) => "query" !== e4.kind || "cache-only" !== e4.context.requestPolicy)(map((e4) => "production" !== process.env.NODE_ENV ? addMetadata(e4, {
      cacheOutcome: "miss"
    }) : e4)(merge([map(mapTypeNames)(filter((e4) => !shouldSkip(e4) && !isOperationCached(e4))(i3)), filter((e4) => shouldSkip(e4))(i3)])))));
    return merge([s2, c2]);
  };
};
var reexecuteOperation = (e3, r2) => e3.reexecuteOperation(makeOperation(r2.kind, r2, {
  requestPolicy: "network-only"
}));
var subscriptionExchange = ({ forwardSubscription: e3, enableAllOperations: r2, isSubscriptionOperation: t2 }) => ({ client: a2, forward: i3 }) => {
  var u3 = t2 || ((e4) => "subscription" === e4.kind || !!r2 && ("query" === e4.kind || "mutation" === e4.kind));
  return (r3) => {
    var t3 = mergeMap((t4) => {
      var { key: i4 } = t4;
      var u4 = filter((e4) => "teardown" === e4.kind && e4.key === i4)(r3);
      return takeUntil(u4)(((r4) => {
        var t5 = e3(makeFetchBody(r4), r4);
        return make((e4) => {
          var o2 = false;
          var i5;
          var u5;
          function nextResult(t6) {
            e4.next(u5 = u5 ? mergeResultPatch(u5, t6) : makeResult(r4, t6));
          }
          Promise.resolve().then(() => {
            if (o2) {
              return;
            }
            i5 = t5.subscribe({
              next: nextResult,
              error(t6) {
                if (Array.isArray(t6)) {
                  nextResult({
                    errors: t6
                  });
                } else {
                  e4.next(makeErrorResult(r4, t6));
                }
                e4.complete();
              },
              complete() {
                if (!o2) {
                  o2 = true;
                  if ("subscription" === r4.kind) {
                    a2.reexecuteOperation(makeOperation("teardown", r4, r4.context));
                  }
                  if (u5 && u5.hasNext) {
                    nextResult({
                      hasNext: false
                    });
                  }
                  e4.complete();
                }
              }
            });
          });
          return () => {
            o2 = true;
            if (i5) {
              i5.unsubscribe();
            }
          };
        });
      })(t4));
    })(filter((e4) => "teardown" !== e4.kind && u3(e4))(r3));
    var p2 = i3(filter((e4) => "teardown" === e4.kind || !u3(e4))(r3));
    return merge([t3, p2]);
  };
};
var fetchExchange = ({ forward: e3, dispatchDebug: r2 }) => (t2) => {
  var n2 = mergeMap((e4) => {
    var n3 = makeFetchBody(e4);
    var a3 = makeFetchURL(e4, n3);
    var i3 = makeFetchOptions(e4, n3);
    "production" !== process.env.NODE_ENV && r2({
      type: "fetchRequest",
      message: "A fetch request is being executed.",
      operation: e4,
      data: {
        url: a3,
        fetchOptions: i3
      },
      source: "fetchExchange"
    });
    var s2 = takeUntil(filter((r3) => "teardown" === r3.kind && r3.key === e4.key)(t2))(makeFetchSource(e4, a3, i3));
    if ("production" !== process.env.NODE_ENV) {
      return onPush((t3) => {
        var n4 = !t3.data ? t3.error : void 0;
        "production" !== process.env.NODE_ENV && r2({
          type: n4 ? "fetchError" : "fetchSuccess",
          message: `A ${n4 ? "failed" : "successful"} fetch response has been returned.`,
          operation: e4,
          data: {
            url: a3,
            fetchOptions: i3,
            value: n4 || t3
          },
          source: "fetchExchange"
        });
      })(s2);
    }
    return s2;
  })(filter((e4) => "teardown" !== e4.kind && ("subscription" !== e4.kind || !!e4.context.fetchSubscriptions))(t2));
  var a2 = e3(filter((e4) => "teardown" === e4.kind || "subscription" === e4.kind && !e4.context.fetchSubscriptions)(t2));
  return merge([n2, a2]);
};
var composeExchanges = (e3) => ({ client: r2, forward: t2, dispatchDebug: n2 }) => e3.reduceRight((e4, t3) => {
  var a2 = false;
  return t3({
    client: r2,
    forward(r3) {
      if ("production" !== process.env.NODE_ENV) {
        if (a2) {
          throw new Error("forward() must only be called once in each Exchange.");
        }
        a2 = true;
      }
      return share(e4(share(r3)));
    },
    dispatchDebug(e5) {
      "production" !== process.env.NODE_ENV && n2({
        timestamp: Date.now(),
        source: t3.name,
        ...e5
      });
    }
  });
}, t2);
var fallbackExchange = ({ dispatchDebug: e3 }) => (r2) => {
  if ("production" !== process.env.NODE_ENV) {
    r2 = onPush((r3) => {
      if ("teardown" !== r3.kind && "production" !== process.env.NODE_ENV) {
        var t2 = `No exchange has handled operations of kind "${r3.kind}". Check whether you've added an exchange responsible for these operations.`;
        "production" !== process.env.NODE_ENV && e3({
          type: "fallbackCatch",
          message: t2,
          operation: r3,
          source: "fallbackExchange"
        });
        console.warn(t2);
      }
    })(r2);
  }
  return filter((e4) => false)(r2);
};
var C = function Client(e3) {
  if ("production" !== process.env.NODE_ENV && !e3.url) {
    throw new Error("You are creating an urql-client without a url.");
  }
  var r2 = 0;
  var t2 = /* @__PURE__ */ new Map();
  var n2 = /* @__PURE__ */ new Map();
  var a2 = /* @__PURE__ */ new Set();
  var o2 = [];
  var i3 = {
    url: e3.url,
    fetchSubscriptions: e3.fetchSubscriptions,
    fetchOptions: e3.fetchOptions,
    fetch: e3.fetch,
    preferGetMethod: e3.preferGetMethod,
    requestPolicy: e3.requestPolicy || "cache-first"
  };
  var s2 = makeSubject();
  function nextOperation(e4) {
    if ("mutation" === e4.kind || "teardown" === e4.kind || !a2.has(e4.key)) {
      if ("teardown" === e4.kind) {
        a2.delete(e4.key);
      } else if ("mutation" !== e4.kind) {
        a2.add(e4.key);
      }
      s2.next(e4);
    }
  }
  var c2 = false;
  function dispatchOperation(e4) {
    if (e4) {
      nextOperation(e4);
    }
    if (!c2) {
      c2 = true;
      while (c2 && (e4 = o2.shift())) {
        nextOperation(e4);
      }
      c2 = false;
    }
  }
  var makeResultSource = (e4) => {
    var r3 = takeUntil(filter((r4) => "teardown" === r4.kind && r4.key === e4.key)(s2.source))(filter((r4) => r4.operation.kind === e4.kind && r4.operation.key === e4.key && (!r4.operation.context._instance || r4.operation.context._instance === e4.context._instance))(O));
    if ("query" !== e4.kind) {
      r3 = takeWhile((e5) => !!e5.hasNext, true)(r3);
    } else {
      r3 = switchMap((r4) => {
        var t3 = fromValue(r4);
        return r4.stale || r4.hasNext ? t3 : merge([t3, map(() => {
          r4.stale = true;
          return r4;
        })(take(1)(filter((r5) => r5.key === e4.key)(s2.source)))]);
      })(r3);
    }
    if ("mutation" !== e4.kind) {
      r3 = onEnd(() => {
        a2.delete(e4.key);
        t2.delete(e4.key);
        n2.delete(e4.key);
        c2 = false;
        for (var r4 = o2.length - 1; r4 >= 0; r4--) {
          if (o2[r4].key === e4.key) {
            o2.splice(r4, 1);
          }
        }
        nextOperation(makeOperation("teardown", e4, e4.context));
      })(onPush((r4) => {
        if (r4.stale) {
          if (!r4.hasNext) {
            a2.delete(e4.key);
          } else {
            for (var n3 of o2) {
              if (n3.key === r4.operation.key) {
                a2.delete(n3.key);
                break;
              }
            }
          }
        } else if (!r4.hasNext) {
          a2.delete(e4.key);
        }
        t2.set(e4.key, r4);
      })(r3));
    } else {
      r3 = onStart(() => {
        nextOperation(e4);
      })(r3);
    }
    return share(r3);
  };
  var u3 = this instanceof Client ? this : Object.create(Client.prototype);
  var p2 = Object.assign(u3, {
    suspense: !!e3.suspense,
    operations$: s2.source,
    reexecuteOperation(e4) {
      if ("teardown" === e4.kind) {
        dispatchOperation(e4);
      } else if ("mutation" === e4.kind) {
        o2.push(e4);
        Promise.resolve().then(dispatchOperation);
      } else if (n2.has(e4.key)) {
        var r3 = false;
        for (var t3 = 0; t3 < o2.length; t3++) {
          if (o2[t3].key === e4.key) {
            o2[t3] = e4;
            r3 = true;
          }
        }
        if (!(r3 || a2.has(e4.key) && "network-only" !== e4.context.requestPolicy)) {
          o2.push(e4);
          Promise.resolve().then(dispatchOperation);
        } else {
          a2.delete(e4.key);
          Promise.resolve().then(dispatchOperation);
        }
      }
    },
    createRequestOperation(e4, t3, n3) {
      if (!n3) {
        n3 = {};
      }
      var a3;
      if ("production" !== process.env.NODE_ENV && "teardown" !== e4 && (a3 = getOperationType(t3.query)) !== e4) {
        throw new Error(`Expected operation of type "${e4}" but found "${a3}"`);
      }
      return makeOperation(e4, t3, {
        _instance: "mutation" === e4 ? r2 = r2 + 1 | 0 : void 0,
        ...i3,
        ...n3,
        requestPolicy: n3.requestPolicy || i3.requestPolicy,
        suspense: n3.suspense || false !== n3.suspense && p2.suspense
      });
    },
    executeRequestOperation(e4) {
      if ("mutation" === e4.kind) {
        return withPromise(makeResultSource(e4));
      }
      return withPromise(lazy(() => {
        var r3 = n2.get(e4.key);
        if (!r3) {
          n2.set(e4.key, r3 = makeResultSource(e4));
        }
        r3 = onStart(() => {
          dispatchOperation(e4);
        })(r3);
        var a3 = t2.get(e4.key);
        if ("query" === e4.kind && a3 && (a3.stale || a3.hasNext)) {
          return switchMap(fromValue)(merge([r3, filter((r4) => r4 === t2.get(e4.key))(fromValue(a3))]));
        } else {
          return r3;
        }
      }));
    },
    executeQuery(e4, r3) {
      var t3 = p2.createRequestOperation("query", e4, r3);
      return p2.executeRequestOperation(t3);
    },
    executeSubscription(e4, r3) {
      var t3 = p2.createRequestOperation("subscription", e4, r3);
      return p2.executeRequestOperation(t3);
    },
    executeMutation(e4, r3) {
      var t3 = p2.createRequestOperation("mutation", e4, r3);
      return p2.executeRequestOperation(t3);
    },
    readQuery(e4, r3, t3) {
      var n3 = null;
      subscribe((e5) => {
        n3 = e5;
      })(p2.query(e4, r3, t3)).unsubscribe();
      return n3;
    },
    query: (e4, r3, t3) => p2.executeQuery(createRequest(e4, r3), t3),
    subscription: (e4, r3, t3) => p2.executeSubscription(createRequest(e4, r3), t3),
    mutation: (e4, r3, t3) => p2.executeMutation(createRequest(e4, r3), t3)
  });
  var d3 = noop;
  if ("production" !== process.env.NODE_ENV) {
    var { next: f3, source: x } = makeSubject();
    p2.subscribeToDebugTarget = (e4) => subscribe(e4)(x);
    d3 = f3;
  }
  var g = composeExchanges(e3.exchanges);
  var O = share(g({
    client: p2,
    dispatchDebug: d3,
    forward: fallbackExchange({
      dispatchDebug: d3
    })
  })(s2.source));
  publish(O);
  return p2;
};
var Q = C;

// src/graphqlClient.ts
var import_exchange_auth = require("@urql/exchange-auth");
var import_graphql_ws = require("graphql-ws");
var useGraphQLClient = (config) => {
  const { accessToken, fetchAuthSession: fetchAuthSession2 } = useAuth();
  const url = config?.url || "/graphql";
  const clientMemo = (0, import_react3.useMemo)(() => {
    let exchanges = [cacheExchange, fetchExchange];
    const authEx = (0, import_exchange_auth.authExchange)(async (utils) => {
      const authSession = await fetchAuthSession2();
      let accessToken2 = authSession?.tokens?.accessToken;
      return {
        addAuthToOperation(operation) {
          if (accessToken2) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${accessToken2}`
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          if (!accessToken2) {
            return false;
          }
          try {
            const expiredTimeInSeconds = accessToken2?.payload?.exp;
            if (!expiredTimeInSeconds) {
              return true;
            }
            const expirationTime = expiredTimeInSeconds * 1e3;
            const currentTime = Date.now();
            const timeUntilExpiration = expirationTime - currentTime;
            return timeUntilExpiration <= 1e4;
          } catch (error2) {
            console.error("Error parsing access token:", error2);
            return true;
          }
        },
        didAuthError(error2, _operation) {
          const didError = error2.graphQLErrors.some(
            (e3) => e3.extensions?.code === "FORBIDDEN" || e3.message?.toLowerCase().includes("expired token") || e3.message?.toLowerCase().includes("no authorization")
          );
          return didError;
        },
        async refreshAuth() {
          const tokens = await fetchAuthSession2({ forceRefresh: true });
          accessToken2 = tokens?.tokens?.accessToken;
          if (!accessToken2) {
            config?.onAuthError?.();
          }
        }
      };
    });
    exchanges = [cacheExchange, authEx, fetchExchange];
    if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_WEBSOCKET_URL) {
      const webSocketUrl = process.env.NEXT_PUBLIC_WEBSOCKET_URL;
      const wsClient = (0, import_graphql_ws.createClient)({
        url: webSocketUrl,
        retryAttempts: 10,
        connectionParams: async () => {
          const authSession = await fetchAuthSession2();
          let accessToken2 = authSession?.tokens?.accessToken;
          return {
            type: "connection_init",
            action: "$connect",
            authToken: accessToken2?.toString()
          };
        }
      });
      exchanges.push(
        subscriptionExchange({
          forwardSubscription(request) {
            const input = { ...request, query: request.query || "" };
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink);
                return { unsubscribe };
              }
            };
          }
        })
      );
    }
    return Q({
      url,
      exchanges
    });
  }, [url]);
  return clientMemo;
};

// src/useInput.ts
var import_react6 = __toESM(require("react"));

// src/useDebounce.ts
var import_react5 = require("react");

// src/useTimeoutFn.ts
var import_react4 = require("react");
function useTimeoutFn(fn, ms = 0) {
  const ready = (0, import_react4.useRef)(false);
  const timeout = (0, import_react4.useRef)();
  const callback = (0, import_react4.useRef)(fn);
  const isReady = (0, import_react4.useCallback)(() => ready.current, []);
  const set = (0, import_react4.useCallback)(() => {
    ready.current = false;
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms);
  }, [ms]);
  const clear = (0, import_react4.useCallback)(() => {
    ready.current = null;
    timeout.current && clearTimeout(timeout.current);
  }, []);
  (0, import_react4.useEffect)(() => {
    callback.current = fn;
  }, [fn]);
  (0, import_react4.useEffect)(() => {
    set();
    return clear;
  }, [ms]);
  return [isReady, clear, set];
}

// src/useDebounce.ts
function useDebounce(fn, ms = 0, deps = []) {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);
  (0, import_react5.useEffect)(reset, deps);
  return [isReady, cancel];
}

// src/useInput.ts
var DEBOUNCE_TIME = 200;
var useInput = (props) => {
  const {
    initialValue,
    isRequired,
    validator,
    isDirtyFunc,
    formatter,
    dependsOn = []
  } = props;
  const [prevValue, setPrevValue] = (0, import_react6.useState)(initialValue);
  const [value2, setValue] = (0, import_react6.useState)(initialValue);
  const [debouncedValue, setDebouncedValue] = import_react6.default.useState(initialValue);
  const [isDirty, setIsDirty] = (0, import_react6.useState)(false);
  const [isValid, setIsValid] = (0, import_react6.useState)(true);
  useDebounce(
    () => {
      setDebouncedValue(value2);
    },
    DEBOUNCE_TIME,
    [value2]
  );
  const checkValidation = (val = value2) => {
    let isValid2 = true;
    if (isRequired) {
      isValid2 = isValid2 && !!val;
    }
    if (validator) {
      isValid2 = isValid2 && validator(val);
    }
    setIsValid(isValid2);
  };
  const onReset = (resetValue) => {
    setValue(resetValue || initialValue);
    setPrevValue(resetValue || initialValue);
    setIsDirty(false);
    setIsValid(true);
    checkValidation(resetValue || initialValue);
  };
  (0, import_react6.useEffect)(() => {
    checkValidation(value2);
    if (isDirtyFunc) {
      setIsDirty(isDirtyFunc(value2, prevValue));
    } else {
      setIsDirty(prevValue != value2);
    }
  }, [value2, isRequired, ...dependsOn]);
  const updateValue = (val) => {
    if (formatter) {
      val = formatter(val);
    }
    setValue(val);
  };
  const onChange = (event) => {
    const val = event.target?.value || event.target?.name || event;
    updateValue(val);
    if (event.target?.type === "radio") {
      console.log("EVENT", event.target.value, event.target.name);
      updateValue(event.target.name);
    } else if (event.target?.value) {
      updateValue(event.target.value);
    } else {
      updateValue(event);
    }
  };
  return {
    initialValue,
    value: value2,
    debouncedValue,
    setValue,
    isDirty,
    isValid,
    reset: onReset,
    checkValidation,
    bind: {
      selected: value2,
      value: value2,
      onChange
    }
  };
};

// src/useActivityDetection.tsx
var import_react7 = require("react");
var import_urql = require("urql");
var UpdateLastSeenMutation = `
  mutation {
    updateUserLastSeen
  }
`;
function useActivityDetection(interval = 6e4) {
  const activityFlag = (0, import_react7.useRef)(false);
  const timerId = (0, import_react7.useRef)(null);
  const [_, updateLastSeen] = (0, import_urql.useMutation)(UpdateLastSeenMutation);
  (0, import_react7.useEffect)(() => {
    updateLastSeen();
  }, []);
  (0, import_react7.useEffect)(() => {
    const activityHandler = () => {
      activityFlag.current = true;
    };
    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);
    window.addEventListener("touchstart", activityHandler);
    const onHandleLastSeenTimer = async () => {
      if (activityFlag.current) {
        await updateLastSeen();
        activityFlag.current = false;
      }
    };
    timerId.current = setInterval(onHandleLastSeenTimer, interval);
    return () => {
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      window.removeEventListener("touchstart", activityHandler);
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [interval]);
}

// src/useUpdate.ts
var import_react8 = require("react");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AuthContext,
  AuthProvider,
  configureAuth,
  currentSession,
  useActivityDetection,
  useAuth,
  useBackgroundColor,
  useGraphQLClient,
  useInput
});
