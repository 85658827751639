import React from "react";
import { Nunito } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Provider } from "urql";
import { AuthProvider, useGraphQLClient } from "@drumbeat/hooks";
import "../styles/globals.css";
import { DEFAULT_AUTH_CONFIG } from "../constants";
import { AppPropsWithLayout } from "../types";

const nunito = Nunito({
  subsets: ["latin"],
  style: ["normal", "italic"],
  weight: ["400", "500", "700", "900"],
  display: "swap",
  variable: "--font-nunito",
});

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: "*",
    },
    autocapture: false,
    loaded: (posthog) => {
      // if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
  });
}
const GraphQLProvider = ({ children }) => {
  const router = useRouter();
  const graphqlClient = useGraphQLClient({
    onAuthError: async () => {
      router.replace("/login");
    },
  });

  // Use React.useEffect to handle client-side rendering
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return <Provider value={graphqlClient}>{children}</Provider>;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const authConfig = { ...DEFAULT_AUTH_CONFIG };
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParameters = new URLSearchParams(window.location.search);
      const gclid = queryParameters.get("gclid");
      if (gclid) window.localStorage.setItem("gclid", gclid);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone) window.localStorage.setItem("timezone", timezone);
    }
  }, []);

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=G-3C1RYV2R17"
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-3C1RYV2R17');
        `}
      </Script>
      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-546G62ZH');
        `}
      </Script>

      <Script id="tiktok-pixel" strategy="lazyOnload">
        {`
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
          var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
          ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


            ttq.load('CSK35D3C77U2VKPCDCD0');
            ttq.page();
          }(window, document, 'ttq');
        `}
      </Script>

      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-546G62ZH"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
      <PostHogProvider client={posthog}>
        <AuthProvider config={authConfig}>
          <Head>
            <title>Anonymous Health: Overcome Habits</title>
            <link
              rel="icon"
              type="image/png"
              sizes="128x128"
              href="/128_favicon.png"
            />
          </Head>
          <GraphQLProvider>
            {/* Wrap the main content in a div for consistent rendering */}
            <div className={nunito.className}>
              {getLayout(<Component {...pageProps} />)}
            </div>
          </GraphQLProvider>
        </AuthProvider>
      </PostHogProvider>
    </>
  );
}
